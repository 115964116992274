<template>
  <div>
    <template v-if="!loading.overall">
      <v-container>
        <SettingsGear :showIcon="true" />
        <!-- One Logo / Slogan -->
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
          >
            <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
          </div>
        </div>
        <div style="height: 55vh">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
          >
            <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
          </div>

          <div>
            <div style="height: 10vh"></div>
            <div style="height: 5vh">
              <p
                class="text-uppercase white--text font-weight-bold text-center"
              >
                search for device
              </p>
            </div>
            <div style="height: 15vh"></div>
            <div style="height: 5vh">
              <div class="row text-center">
                <div class="col-12 mb-3">
                  <div
                    :style="`background-color: ${getActionColor}; border-radius: 30px`"
                  >
                    <p
                      @click="search"
                      class="text-center text-white text-uppercase py-2"
                      style="font-size: 1.7em; font-weight: bold"
                    >
                      search
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img style="height: 3.5em" src="@/assets/logos/one_logo_black.png" />
        </div>
      </v-container>
    </template>
    <template v-else> Loading...</template>
    <van-popup
      v-model="showBindDialog"
      class="bind-dialog"
      round
      :close-on-click-overlay="false"
    >
      <BindDeviceDialog
        :is-bind="isBind"
        :mac-address="macAddress"
        v-show="showBindDialog"
        v-on:onBindStatusChanged="onBindStatusChanged"
        v-on:onCloseBindDialog="onCloseBindDialog"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bluetoothRepository from "@/bluetooth/BluetoothRepository.js";
import BindDeviceDialog from "../../components/dialog/BindDeviceDialog";
import { Toast } from "vant";

import log from "@/util/log/";

export default {
  name: "pair-landing",
  components: { BindDeviceDialog },
  data() {
    return {
      loading: {
        overall: true,
      },
      response: {},
      payload: {},

      showBindDialog: false,
      isBind: false,
      macAddress: "",
    };
  },
  created() {
    this.setup();
  },
  methods: {
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      await this.setLoadingState(true);
      // Methods
      await this.setLoadingState(false);
    },
    /* Misc */
    async setLoadingState(loadingState) {
      this.loading.overall = loadingState;
    },

    search() {
      bluetoothRepository.startPair((macAddress, isBind) => {
        this.hideLoading();
        Toast.clear();
        if (!isBind) {
          //没有绑定的设备，弹出对话框，去服务器查询
          log(`address = ${macAddress}`);
          this.macAddress = macAddress;
          this.isBind = isBind;
          this.showBindDialog = true;
        } else {
          bluetoothRepository.disconnect();
          this.isBind = isBind;
          this.showBindDialog = true;
        }
      });
    },
    onCloseBindDialog() {
      this.showBindDialog = false;

      bluetoothRepository.disconnect();
    },
    onBindStatusChanged(isBind) {
      this.isBind = isBind;
    },
  },
  computed: {
    ...mapGetters(["getMainColor", "getAccentColor", "getActionColor"]),
  },
};
</script>

<style lang="less" scoped>
.bind-dialog {
  background-color: #222222 !important;
}
</style>
