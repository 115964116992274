<template>
  <div class="bind-device-dialog-container">
    <div class="bind-device-tips" v-show="!isBind">
      <p class="binding-device-title">Connect Device</p>
      <p class="binding-device-content">
        This is the first time this device<br />
        has been connected.
      </p>
      <p class="binding-device-tips">
        Would you like to register<br />
        it to this account?
      </p>
      <div class="button-handle-container">
        <div class="later" @click="dismissDialog">NO</div>
        <div class="bind-device-button" @click="bindDevice">YES</div>
      </div>
    </div>

    <div class="bind-failure-tips" v-show="isBind">
      <p class="binding-device-title text-uppercase">Already paired</p>
      <p class="binding-device-content">
        This device is already paired with another account, please unbind it
        first.
      </p>
      <div class="later" @click="dismissDialog">CLOSE</div>
    </div>
  </div>
</template>

<script>
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import device from "../../api/device";
import log from "@/util/log/";

export default {
  name: "BindDeviceDialog",
  props: {
    isBind: false,
    macAddress: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    async bindDevice() {
      this.showLoading("Binding....");
      device
        .bindDevice(this.macAddress)
        .then((data) => {
          return bluetoothRepository.afterConnectHandle();
        })
        .catch((err) => {
          log("绑定并且连接成功");
          this.$emit("onBindStatusChanged", true);
          this.hideLoading();
        });
    },
    dismissDialog() {
      log("ss");
      this.$emit("onCloseBindDialog");
    },
  },
};
</script>

<style lang="less" scoped>
.bind-device-dialog-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  background-color: #222222;

  .bind-device-tipsg {
    display: flex;
    margin-top: 1vh;
    flex-direction: column;
    align-items: center;
  }

  .bind-failure-tips {
    display: flex;
    margin-top: 1vh;
    flex-direction: column;
    align-items: center;
    width: 80vw;

    .tips-text {
      background-color: #f6f6f6;
      color: #111111;
    }

    .later {
      flex: 1;
      height: 7vh;
      line-height: 7vh;
      z-index: 99999;
      font-weight: bold;
      background-color: #222222;
      color: #ffffff;
      font-size: 24px;
    }
  }
}

.binding-device-title {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-top: 15px;
  font-size: 24px;
  letter-spacing: 2px;
}

.binding-device-content {
  font-size: 14px;
  text-align: center;
  color: #999999;
  line-height: 16px;
}

.binding-device-tips {
  font-size: 14px;
  color: #d9a845;
  text-align: center;
  line-height: 16px;
}

.button-handle-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  text-align: center;

  .later {
    flex: 1;
    height: 7vh;
    line-height: 7vh;
    z-index: 99999;
    font-weight: bold;
    background-color: #222222;
    color: #ffffff;
    font-size: 24px;
  }

  .bind-device-button {
    flex: 1;
    height: 7vh;
    line-height: 7vh;
    z-index: 99999;
    font-weight: bold;
    background-color: #fec44f;
    color: #ffffff;
    font-size: 24px;
    border-radius: 16px;
  }
}
</style>